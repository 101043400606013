import './Eligiblity.css';
import graph from './../../Assets/Icons/Graph.svg';

const Eligiblity = () => {
    return (
        <div className={"eligibility-container container"} id={"eligibility"}>
            <div className={"eligibility-title container-title"}>
                Eligibility
            </div>
            <div className={"eligibility-desc"}>
                For <b>engineering students</b> and <b>recent graduates</b> who want to kickstart their career
            </div>
            <div id={"prospects-title"} >
                Career Prospectus
            </div>
            <img src={graph} id={"prospect-graph"} alt={"placeprep-prospects"}/>
        </div>
    )
};

export default Eligiblity;