import './FAQ.css';
import {useState} from "react";

const FAQ = () => {
    const [data, setData] = useState({
        faqData : [
            {
                faqQuestion:'When are live classes held?',
                faqAnswer:'Classes are held every day in the week except Sunday. Considering learners might have their regular course work going on, the classes would be in the late evening',
                isCollapsed:true
            },
            {
                faqQuestion:'What if I miss a lecture?',
                faqAnswer:'All lectures are recorded and you will have the recordings after the lecture is completed.',
                isCollapsed:true
            },
            {
                faqQuestion:'How will my doubts get solved?',
                faqAnswer:'You can clarify the doubts at the end of each class every day.',
                isCollapsed:true
            },
            {
                faqQuestion:'What are the pre-requisites for this course?',
                faqAnswer:'A laptop with good internet connectivity is needed.',
                isCollapsed:true
            },
            {
                faqQuestion:'Do you guarantee job?',
                faqAnswer:'No, we do not guarantee jobs, placement Assistance is subject to your performance during the course.\n' +
                    'However, we try our best to expose you to multiple number of Opportunities.',
                isCollapsed:true
            },
            {
                faqQuestion:'Is there a certificate provided at the end of the programme?',
                faqAnswer:'Yes, After the completion of the program, you will receive a Course Completion certificate.',
                isCollapsed:true
            }
        ]
    });

    const onToggleFAQ = event => {
        let index = event.target.dataset.index;
        let faqs = data.faqData;
        faqs[index].isCollapsed = !faqs[index].isCollapsed;
        setData({faqData:faqs});
    };

    return (
        <div className={"faq-container container"} id={"faq"}>
            <div className={"faq-title container-title"}>
                FAQs
            </div>
            <div className={"faq-list"}>
                {data.faqData.map((item, index)=>{
                    return (
                        <div className={"faq-item"}>
                            <div className={"item-question"}>
                                <div className={"question-content"}>
                                    {item.faqQuestion}
                                </div>
                                <span className={"question-control"} data-index={index} onClick={onToggleFAQ}>
                                    {item.isCollapsed?"+":"-"}
                                </span>
                            </div>
                            {item.isCollapsed===false?(
                                <div className={"item-answer"}>
                                    {item.faqAnswer}
                                </div>
                            ):null}
                        </div>
                    );
                })}

            </div>
        </div>
    );
};

export default FAQ;