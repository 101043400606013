import './CommunityStripTwo.css';

const CommunityStripTwo = props => {
    const navigateToURL = () => {
        window.open(props.buttonURL, "_self");
    };
    return (
        <div className={"cmnty-strip-2"} style={props.style}>
            <div className={"strip-content"}>
                <div className={"strip-title"}>
                    Do You Still Have Questions?
                </div>
                <div className={"strip-sub-desc"}>
                    Talk to our career counseller for free
                </div>
            </div>
            <button className={"button button-primary"} style={{'width':'240px', 'height':'40px'}} onClick={navigateToURL}>Request Callback</button>
        </div>
    )
};

export default CommunityStripTwo;