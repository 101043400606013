import './CompaniesHire.css';
import wipro from './../../Assets/Icons/wipro.svg';
import ibm from './../../Assets/Icons/ibm.svg';
import tcs from './../../Assets/Icons/tcs.svg';
import infosys from './../../Assets/Icons/infosys.svg';
import capgemini from './../../Assets/Icons/capgemini.svg';
import accenture from './../../Assets/Icons/accenture.svg';
import hcl from './../../Assets/Icons/hcl.svg';
import deloitte from './../../Assets/Icons/deloitte.svg';
import mindtree from './../../Assets/Icons/mindtree.svg';
import cts from './../../Assets/Icons/cognizant.svg';
import techmahindra from './../../Assets/Icons/techmahindra.svg';
import valuelabs from './../../Assets/Icons/valuelabs.svg';
import epam from './../../Assets/Icons/epam.svg';
import dxc from './../../Assets/Icons/dxc.svg';
import maq from './../../Assets/Icons/maq.svg';
import opentext from './../../Assets/Icons/opentext.svg';


const CompaniesHire = () => {
    const companiesList = [
        {label:'Wipro', img:wipro}, {label:'IBM', img:ibm}, {label:'TCS', img:tcs}, {label:'Infosys', img:infosys},
        {label:'CapGemini', img:capgemini}, {label:'Accenture', img:accenture}, {label:'EPAM', img:epam}, {label:'HCL', img:hcl}, {label:'Deloitte', img:deloitte},
        {label:'Mindtree', img:mindtree}, {label:'CTS', img:cts}, {label:'DXC', img:dxc}, {label:'Tech Mahindra', img:techmahindra}, {label:'MAQ', img:maq},
        {label:'OpenText', img:opentext}, {label:'Value Labs', img:valuelabs}
    ];
    return (
        <div className={"companies-container container"}>
            <div className={"companies-title container-title"}>
                Companies that hire
            </div>
            <div className={"companies-list"}>
                {
                    companiesList.map((item, index)=>{
                        return (
                            <div className={"companies-item"} key={index} title={item.label}>
                                <img src={item.img} alt={item.label+"icon"}/>
                            </div>
                        )
                    })
                }
            </div>
            <div className={"many-more"}>and many more....</div>
        </div>
    )
};

export default CompaniesHire;