import './Header.css';
import logo from './../../Assets/Images/logo.svg';
import {useState} from "react";
import HeaderSubItem from "./HeaderSubItem/HeaderSubItem";

const Header = () => {
    const [menuItems, setMenuItems] = useState({
        subMenuItems : [
            {label:'Why CIPP?', link:'/#services'},
            {label:'Curriculum', link:'/#curriculum'},
            {label:'Career Prospects', link:'/#eligibility'},
            {label:'Pricing', link:'/#pricing'},
            {label:'FAQ', link:'/#faq'}
        ]
    });

    const navToURL = (event) => {
        let url = event.target.dataset.url;
        if(url){
            window.open(url, "_self");
        }
    };

    return (
        <div id="menu-container">
            <a id="menu-brand" href={"/"}>
                <img src={logo} alt="placeprep-logo" />
            </a>
            <div id="sub-menu-container">
                {
                    menuItems.subMenuItems.map((subItem, index)=>{
                        return <HeaderSubItem key={index} label={subItem.label} link={subItem.link}/>
                    })
                }
                {/*{*/}
                {/*    (window.innerWidth<1000)?(*/}
                {/*        <button style={{'margin':'0 25px', 'width':'150px', 'height':'35px'}} className="button button-transparent-with-border"*/}
                {/*                data-url={"/external-link?iframe-url=https://docs.google.com/forms/d/e/1FAIpQLSfs8ZHa4DssZtGhsCg-_7GHRK597oKgdoSA-oTKeLebd0WPcA/viewform?embedded=true&iframe-width=640&iframe-height=810"}*/}
                {/*                onClick={navToURL}>Request Callback</button>*/}
                {/*    ):null*/}
                {/*}*/}
                <button style={{'margin':'0 25px', 'height':'35px'}} className="button button-primary" data-url={"/external-link?iframe-url=https://docs.google.com/forms/d/e/1FAIpQLSfs8ZHa4DssZtGhsCg-_7GHRK597oKgdoSA-oTKeLebd0WPcA/viewform?embedded=true&iframe-width=640&iframe-height=810"} onClick={navToURL}>Request Callback</button>
            </div>
        </div>
    );
}

export default Header;
