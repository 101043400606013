import './PricingCard.css';
import timer from './../../../Assets/Icons/timer.svg';

const PricingCard = props => {
    const navToURL = (event) => {
        let url = event.target.dataset.url;
        if(url){
            window.open(url, "_self");
        }
    };
    return (
        <div className={"pricing-card"}>
            <div className={"start-date"}>
                Batch starts on <b>20th December</b>
            </div>
            <div className={"duration"}>
                Duration: <b>3 Months</b>
            </div>
            <div className={"price-lay"}>
                <div className={"price-org"}>
                    ₹ 10,000
                </div>
                <div className={"price-mod"}>
                    ₹ 7,999
                </div>
                <span className={"no-tax"}>
                    (Inclusive of all taxes)
                </span>
            </div>
            <div className={"discount"}>
                20% off
            </div>
            <div className={"filling-status"}>
                <img src={timer} alt={"timer_img"} />
                Limited seats left
            </div>
            <button className={"button button-primary"} style={{'width':'100%', 'height':'40px', 'marginTop':'20px'}} data-url={props.paymentURL} onClick={navToURL}>
                Enroll Now
            </button>
        </div>
    )
};

export default PricingCard;