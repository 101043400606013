import './Pricing.css';
import PricingCard from "./PricingCard/PricingCard";

const Pricing = () => {
    return (
        <div className={"pricing-container container"} id={"pricing"}>
            <div className={"pricing-title container-title"}>
                Pricing
            </div>
            <div className={"container-description"}>
                Placeprep CIPP is a very affordable programme which costs <b>₹2,700 per month</b>. That is exactly like your restaurant bill!
            </div>
            <div className={"pricing-list"}>
                <PricingCard paymentURL={"https://pmny.in/7rwzNllghKN8"}/>
            </div>
        </div>
    )
};

export default Pricing;