import './ProvideList.css';
import {useState} from "react";
import ProvideListCard from "./ProvideListCard/ProvideListCard";
import UnstructuredIcon from './../../Assets/Icons/Unstructured.svg';
import RecordedIcon from './../../Assets/Icons/Recorded.svg';
import InexperiencedIcon from './../../Assets/Icons/inexperienced.svg';
import TheroticalIcon from './../../Assets/Icons/thumb-down.svg';
import NoAssistanceIcon from './../../Assets/Icons/NoAssistance.svg';
import UnAffordableIcon from './../../Assets/Icons/Unaffordale.svg';
import StructuredIcon from './../../Assets/Icons/Structured.svg';
import LiveIcon from './../../Assets/Icons/Live.svg';
import experiencedIcon from './../../Assets/Icons/Experienced.svg';
import handsOnIcon from './../../Assets/Icons/Thumb-up.svg';
import assistanceIcon from './../../Assets/Icons/Assistance.svg';
import affordableIcon from './../../Assets/Icons/Affordable.svg';

const ProvideList = () => {
    const [data, setData] = useState({
        othersprovide: {
            title:'What Others are',
            listItems:[
                {label:'Unstructured Curriculum', description:'Scattered articles, blogs & videos which are old and outdated', icon:UnstructuredIcon},
                {label:'Recorded Classes', description:'They are boring, not interactive and has less completion rate', icon:RecordedIcon},
                {label:'Inexperienced Trainers', description:'Taught by trainers who lacks IT/software Interview experience', icon:NoAssistanceIcon},
                {label:'Theoretical', description:'Limited practice & more focus on theoretical knowledge', icon:InexperiencedIcon},
                {label:'No Career Assistance', description:'They are just technical content with no focus on Interviews', icon:TheroticalIcon},
                {label:'Unaffordable fees', description:'They collect high fees and also make you sign income sharing agreement which binds you to pay hefty amounts', icon:UnAffordableIcon}
            ]
        },
        weprovide: {
            title:'What Placeprep is',
            listItems:[
                {label:'Structured Curriculum', description:'Thoughtfully designed, which makes your learning easy & comfortable', icon:StructuredIcon},
                {label:'Live Classes', description:'Our live interactive classes makes learning fun & enjoyable', icon:LiveIcon},
                {label:'Experienced Trainers', description:'Our instructors are Industry experts who are successful in this field', icon:experiencedIcon},
                {label:'Hands On', description:'Learn by practice approach along with mock tests and  interviews', icon:handsOnIcon},
                {label:'Complete Career-Services', description:'We develop your interview skills, placement support and many more', icon:assistanceIcon},
                {label:'Affordable fees', description:'Very affordable fee with strictly no conditional agreements or contracts', icon:affordableIcon}
            ]
        }
    });

    return (
        <div className={"Provide-list-container container"}>
            <ProvideListCard data={data.othersprovide}/>
            <ProvideListCard data={data.weprovide}/>
        </div>
    )
};

export default ProvideList;
