import ServiceBanner from './../../Assets/Images/ServiceBanner.png';
import './Services.css';
import ServiceItem from "./ServiceItem/ServiceItem";
import ServiceOneLogo from './../../Assets/Icons/ServiceOne.svg';
import ServiceTwoLogo from './../../Assets/Icons/SeviceTwo.svg';
import ServiceThreeLogo from './../../Assets/Icons/ServiceThree.svg';
import ServiceFourLogo from './../../Assets/Icons/ServiceFour.svg';
import ServiceFiveLogo from './../../Assets/Icons/ServiceFive.svg';
import ServiceSixLogo from './../../Assets/Icons/ServiceSix.svg';


const Services = () => {

    const serviceData = [
        {shade:"#FE7C56", cardLogo:ServiceOneLogo, cardContent:"Live interactive classes by industry experts"},
        {shade:"#A277DC", cardLogo:ServiceTwoLogo, cardContent:"24/7 Doubt clearance for the students"},
        {shade:"#FFB425", cardLogo:ServiceThreeLogo, cardContent:"Limited batch strength for better interaction"},
        {shade:"#64B7F4", cardLogo:ServiceFourLogo, cardContent:"Resume building & Interview preparation"},
        {shade:"#4CA772", cardLogo:ServiceFiveLogo, cardContent:"Company specific mock tests & interviews"},
        {shade:"#FAA5C3", cardLogo:ServiceSixLogo, cardContent:"Placement assistance based on performance"}
    ]
    return (
        <div className={"service-container container"} id={"services"}>
            <div className={"services-banner"}>
                <img src={ServiceBanner} alt={"banner"} />
            </div>
            <div className={"services-list"}>
                <p className={"service-hint"}>WHAT WE PROVIDE</p>
                <div className={"service-title"}>
                    Programme Overview
                </div>
                <div className={"service-items"}>
                    {
                        serviceData.map((item, index)=>{
                            return (
                                <ServiceItem key={index} shade={item.shade} cardLogo={item.cardLogo} cardContent={item.cardContent}/>
                            )
                        })
                    }

                </div>
            </div>
        </div>
    );
};

export default Services;