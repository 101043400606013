import Splash from "../Splash/Splash";
import CommunityStrip from "../CommunityStrip/CommunityStrip";
import Services from "../Services/Services";
import ProvideList from "../ProvideList/ProvideList";
import Pricing from "../Pricing/Pricing";
import FAQ from "../FAQ/FAQ";
import CommunityStripTwo from "../CommunityStripTwo/CommunityStripTwo";
import CompaniesHire from "../CompaniesHire/CompaniesHire";
import Eligiblity from "../Eligiblity/Eligiblity";
import FAB from "../FAB/FAB";
import Syllabus from "../Syllabus/Syllabus";


const Home = () => {
    const links = {
        callbackLink : "/external-link?iframe-url=https://docs.google.com/forms/d/e/1FAIpQLSfs8ZHa4DssZtGhsCg-_7GHRK597oKgdoSA-oTKeLebd0WPcA/viewform?embedded=true&iframe-width=640&iframe-height=810",
        communityLink : "/external-link?iframe-url=https://docs.google.com/forms/d/e/1FAIpQLSfVL_vzaFqeNZSKwrBVsJueNgEdzYNjM7DgMtle6SkJWcNU5w/viewform?embedded=true&iframe-width=640&iframe-height=1200",
        campusAssociate: "/external-link?iframe-url=https://docs.google.com/forms/d/e/1FAIpQLSfkhVerqtPTy4tp8GUmwE7zw3zcVGxP3yQ5Mf_S5MV7AwAeCg/viewform?embedded=true&iframe-width=640&iframe-height=1585"
    };
    return (
        <div>
            <Splash />
            <CommunityStrip id={"community"} description={"Register yourself for free interview tips and job oppurtunities"} buttonLabel={"Join Placeprep Community"} buttonURL={links.communityLink}>
                <span style={{'color':'var(--primary-color)'}}>Join</span> Placeprep Community
            </CommunityStrip>
            <Services />
            <ProvideList />
            <CommunityStrip id={"curriculum"} description={"Master Aptitude, Logical reasoning, Programming Basics, Fundamental data structures and algorithms, CS Fundamentals, Verbal Communication and Interview Skills "} buttonLabel={"Download Syllabus"}
            buttonURL={"https://drive.google.com/file/d/1CuUxs_5sRIh4y56nwXkziIfXzEuilEj1/view?usp=sharing"}>
                Industry leading curriculum that gets you placed
            </CommunityStrip>
            <Syllabus />
            <Eligiblity />
            <CompaniesHire />
            <Pricing />
            <FAQ />
            <CommunityStripTwo style={{marginTop: '100px'}} buttonURL={links.callbackLink}/>
            <FAB />
        </div>
    );
}

export default Home;