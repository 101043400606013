import './CommunityStrip.css';

const CommunityStrip = (props) => {
    const navigateToURL = () => {
        window.open(props.buttonURL, "_self");
    };
    return (
        <div className="community-strip-container container" id={props.id} style={{paddingTop: "65px"}}>
            <div className="community-strip-title">
                {props.children}
            </div>
            <div className={"community-strip-desc"}>
                {props.description}
            </div>
            <button className={"button button-primary"} style={{'width':'240px', 'height':'40px', 'marginTop':'20px'}} onClick={navigateToURL}>{props.buttonLabel}</button>
        </div>
    );
}

export default CommunityStrip;