import "./App.css";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import IFrame from "./components/IFrame/IFrame";
import Home from "./components/Home/Home";

const App = () => {
    return (
      <Router>
          <div className="App">
              <Header/>
              <Routes>
                  <Route path={"/"} element={<Home/>}/>
                  <Route path={"/external-link"} element={<IFrame/>}/>
              </Routes>
              <Footer />
          </div>
      </Router>
    );
}

export default App;
