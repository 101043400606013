import './IFrame.css';

const IFrame = () => {

    const url = new URL(window.location.href);
    const formParam = url.searchParams.get('iframe-url');
    const formWidth = url.searchParams.get('iframe-width');
    const formHeight = url.searchParams.get('iframe-height');

    return (
        <div id={"external-frame"}>
            {(formParam && formWidth && formHeight)?
                <iframe
                    src={formParam}
                    width={"100%"} height={formHeight} frameBorder="0" marginHeight="0" marginWidth="0">Loading…
                </iframe>:
                <p>Problem in loading the content. Please contact support team.</p>
            }
        </div>
    );
};

export default IFrame;