import './SyllabusItem.css';

const SyllabusItem = props => {
    return (
        <div className={(props.isActive?"syll-active":"")+" syllabus-item"} onClick={()=>props.changeSection(props.index)}>
            <p className={"syl-item-head"}>
                {props.title}
            </p>
            <p className={"sty-sub-head"}>
                {props.subtitle}
            </p>
        </div>
    );
};

export default SyllabusItem;