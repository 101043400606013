import './ServiceItem.css';

const ServiceItem = props => {
    const extraStyling = {
        'border' : '1px solid '+props.shade
    };
    return (
        <div style={extraStyling} className={"service-card"}>
            <div style={{backgroundColor: props.shade+'4D'}} className={"card-icon"}>
                <img src={props.cardLogo} alt={"service_logo"}/>
            </div>
            <p className={"card-content"}>
                {props.cardContent}
            </p>
        </div>
    )
};

export default ServiceItem;