import './HeaderSubItem.css';

const HeaderSubItem = prop => {
    const navigateToURL = () => {
        if(prop.link){
            window.location.href = prop.link;
        }
    }
    return(
        <div className="sub-menu-item" onClick={navigateToURL}>
            <span>{prop.label}</span>
        </div>
    );
};

export default HeaderSubItem;
