import './ProvideListCard.css';
import UnstructuredIcon from './../../../Assets/Icons/Unstructured.svg';

const ProvideListCard = (props) => {
    return (
        <div className={"provide-list-card"}>
            <div className={"card-title"}>
                {props.data.title}
            </div>
            <hr/>
            <div className={"provide-list-items-container"}>
                {
                    props.data.listItems.map((item, index)=>{
                        return (
                            <div key={index} className={"provide-list-item"}>
                                <div className={"item-icon"}>
                                    <img src={item.icon} alt={"icon"} />
                                </div>
                                <div className={"item-content"}>
                                    <div className={"item-title"}>
                                        {item.label}
                                    </div>
                                    <div className={"item-description"}>
                                        {item.description}
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    )
}
export default ProvideListCard;