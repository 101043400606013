import './Footer.css';
import fbicon from './../../Assets/Icons/facebbok-icon.svg';
import twittericon from './../../Assets/Icons/twitter-icon.svg';
import instagramicon from './../../Assets/Icons/instalogo.png';
import youtubeicon from './../../Assets/Icons/youtube-icon.svg';
import linkedin from './../../Assets/Icons/linkedin-icon.svg';
import mail from './../../Assets/Icons/mail-black-icon.svg';
import phone from './../../Assets/Icons/phone-icon.svg';
import telegram from './../../Assets/Icons/telegram.svg';
import logo from './../../Assets/Images/logo.svg';

const Footer = () => {
    return (
        <div id={"footer"}>
            <div id={"footer-content"}>
                <div className={"footer-brand-img"}>
                    <img src={logo} alt={"placeprep_logo"}/>
                </div>
                <div id={"campus-associate"} className={"footer-content-item"} style={{'fontSize':'28px', 'fontWeight':'500'}}>
                    Want to be a placeprep Campus Associate? &nbsp;
                    <a href={"/external-link?iframe-url=https://docs.google.com/forms/d/e/1FAIpQLSfkhVerqtPTy4tp8GUmwE7zw3zcVGxP3yQ5Mf_S5MV7AwAeCg/viewform?embedded=true&iframe-width=640&iframe-height=1800"}>Apply Now</a>
                </div>
                <div className={"footer-content-item footer-contact"}>
                    <span className={"contact-label"}>
                        Reach us at
                    </span>
                    <span className={"contact-item"}>
                        <img src={mail} />
                        <a href={"mailto:support@placeprep.com"}>support@placeprep.com</a>
                    </span>
                    <span className={"contact-item"}>
                        <img src={phone} />
                        +91 9381466920 (whatsapp only)
                    </span>
                </div>
                <span className={"footer-content-item copyright"}>
                    © 2021 Placeprep solutions pvt.ltd | All rights reserved
                </span>
            </div>
            <div id={"footer-social-media"}>
                <div className={"social-media-content"}>
                    <span className={"social-hint"}>
                    Follow us on
                    </span>
                    <div id={"icons-container"}>
                        <a className={"social-icon"} href={"https://www.instagram.com/placeprep/"} target={"_blank"}>
                            <img src={instagramicon} alt={"insta_icon"} />
                        </a>
                        <a className={"social-icon"} href={"https://www.linkedin.com/company/placeprep"} target={"_blank"}>
                            <img src={linkedin} alt={"linkedin_icon"} />
                        </a>
                        <a className={"social-icon"} href={"https://t.me/placeprep"} target={"_blank"}>
                            <img src={telegram} alt={"insta_icon"} />
                        </a>
                        <a className={"social-icon"} href={"https://www.youtube.com/channel/UCLm7PmpYvWu3Q5HH7ODyXHw"} target={"_blank"}>
                            <img src={youtubeicon} alt={"insta_icon"} />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;