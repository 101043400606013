import './Syllabus.css';
import SyllabusItem from "./SyllabusItem/SyllabusItem";
import {useState} from "react";

const Syllabus = props => {
    const [syllabus, setSyllabus] = useState([
        {
            cardTitle:"Section 1",
            cardSubTitle:"Week 1-4",
            fullTitle:"Aptitude & Logical Reasoning (Week 1 -4)",
            items:[
                {
                    name:"Aptitude (Week 1-2)",
                    subItems:[
                        "Number System", "LCM & HCF", "Averages", "Ratio & Proportion", "Algebra", "Percentage",
                        "Profit & Loss", "Simple & Compound Interest", "Speed Time and Distance", "Time and work", "Allegation and Mixture", "Logarithms",
                        "Permutation and combinations", "Probability", "Geometry and coordinate Geometry","Clocks and Calendar","Series and progression", "Problems on Trains", "Problems on Ages"
                    ]
                },
                {
                    name:"Logical Reasoning (Week 3-4)",
                    subItems:[
                        "Coding deductive Logic","Blood Relations","Directional Sense","Objective Reasoning","Selection Decision Table",
                        "Statement and Conclusion","Seating Arrangement","Coding and Decoding","Analogy and classification pattern Recognition",
                        "Coding pattern and Number series pattern Recognition","Logical and word sequence","Data sufficiency",
                        "CryptArithmetic","Data Interpretation",
                    ]
                }
            ]
        },
        {
            cardTitle:"Section 2",
            cardSubTitle:"Week 4-10",
            fullTitle:"Technical Concepts (Week 4-10)",
            items:[
                {
                    name:"Programming Basics (week 4-7)",
                    subItems:[
                        "C Language Overview","Data Types", "Operators and Expressions", "Input/Output",
                        "Conditional Statements", "Arrays and Pointers", "Strings", "Functions",
                        "Recursion", "Storage classes and preprocessor Directives", "Structures and Union",
                        "Files and dynamic memory allocation", "C ++ & OOPs introduction", "Classes and objects",
                        "Inheritance", "Encapsulation", "Abstraction", "polymorphism", "Exception Handling"
                    ]
                },
                {
                    name:"Fundamental Data Structures & Algorithms (Week 8-9)",
                    subItems:[
                        "Time & Space Complexity", "Array", "Linked List", "Stack", "Queue", "Trees", "Graphs", "Basic Searching and sorting algorithms",
                    ]
                },
                {
                    name:"Computer science fundamentals (Week 10)",
                    subItems:[
                        "Database Management Systems", "Operating Systems", "Computer Networks", "Software Development Lifecycle"
                    ]
                }
            ]
        },
        {
            cardTitle:"Section 3",
            cardSubTitle:"Week 11-12",
            fullTitle:"Communication Skills & Mock Tests (Week 11-12)",
            items:[
                {
                    name:"Verbal Communication Skills (Week 11)",
                    subItems:[
                        "Synonyms", "Antonyms", "Error Identification", "Sentence improvement and Completion", "Subject verb Agreement", "Tenses and Articles",
                        "Preposition and Conjunction", "Speech and Voice", "Para jumbles", "Reading comprehension", "Essay writing"
                    ]
                },
                {
                    name:"Interview Skills and Mock tests (week 12)",
                    subItems:[
                        "Resume building", "Linked in profile building", "Hr interview preparation", "JAM & Group discussions", "Technical interview preparation", "Mock interviews"
                    ]
                }
            ]
        }
    ]);

    const [currentSyllabus, setCurrentSyllabus] = useState({
        cardTitle:"Section 1",
        cardSubTitle:"Week 1-4",
        fullTitle:"Aptitude & Logical Reasoning (Week 1 -4)",
        items:[
            {
                name:"Aptitude (Week 1-2)",
                subItems:[
                    "Number System", "LCM & HCF", "Averages", "Ratio & Proportion", "Algebra", "Percentage",
                    "Profit & Loss", "Simple & Compound Interest", "Speed Time and Distance", "Time and work", "Allegation and Mixture", "Logarithms",
                    "Permutation and combinations", "Probability", "Geometry and coordinate Geometry","Clocks and Calendar","Series and progression", "Problems on Trains", "Problems on Ages"
                ]
            },
            {
                name:"Logical Reasoning (Week 3-4)",
                subItems:[
                    "Coding deductive Logic","Blood Relations","Directional Sense","Objective Reasoning","Selection Decision Table",
                    "Statement and Conclusion","Seating Arrangement","Coding and Decoding","Analogy and classification pattern Recognition",
                    "Coding pattern and Number series pattern Recognition","Logical and word sequence","Data sufficiency",
                    "CryptArithmetic","Data Interpretation",
                ]
            }
        ]
    });

    const changeSection = index => {
        setCurrentSyllabus(syllabus[index]);
    }

    return (
        <div id={"syllabus"} className={"container"}>
            <div className={"syllabus-mtd"}>
                <div className={"eligibility-title container-title"}>
                    Course <span style={{"color":"#0061FF"}}>Structure</span>
                </div>
                <div className={"syllabus-items"}>
                    {
                        syllabus.map((item, index)=>{
                            return <SyllabusItem isActive={item.cardTitle===currentSyllabus.cardTitle} title={item.cardTitle} subtitle={item.cardSubTitle} key={index} index={index} changeSection={changeSection}/>;
                        })
                    }
                </div>
            </div>
            <div className={"syllabus-details"}>
                <div className={"syllabus-chart"}>
                    <div className={"chart-title"}>
                        {currentSyllabus.fullTitle}
                    </div>
                    <div className={"chart-items"}>
                        {
                            currentSyllabus.items.map((item, index)=>{
                                return (
                                    <div className={"item-collection"} key={index}>
                                        <p className={"item-collection-title"}>{index+1}. {item.name}</p>
                                        <div className={"collection"}>
                                            {
                                                item.subItems.map((subItem, subIndex)=>{
                                                    return (
                                                        <div className={"collection-item"} key={subIndex}>{subIndex+1}. {subItem}</div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Syllabus;