import './FAB.css';
import whatsapplogo from './../../Assets/Icons/whatsapp.svg';

const FAB = () => {
    return (
        <a id={"fab"} href={"https://wa.me/message/CLTHRSXXBICOI1"}>
            <img src={whatsapplogo} alt={"whatsapp_logo"} />
        </a>
    );
};

export default FAB;