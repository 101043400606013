import SplashBanner from './../../Assets/Images/SplashBanner.png';
import calenderLogo from './../../Assets/Icons/Calender.svg';
import calenderOnlyLogo from './../../Assets/Icons/CalenderOnly.svg';

import './Splash.css';
const Splash = props => {
    const navToURL = (event) => {
        let url = event.target.dataset.url;
        if(url){
            window.open(url, "_self");
        }
    };
    return (
        <div className="splash-container container">
            <div className="splash-content">
                <div className="splash-content-label">
                    GET YOUR DREAM JOB WITH US!
                </div>
                <div className="splash-content-title">
                    Coding Interview Preparation Programme (CIPP)
                </div>
                <div className="splash-content-desc">
                    An excellent programme to learn, practice and land a tech job of ₹3.5 - ₹8+ LPA
                </div>
                <div className="slash-content-items">
                    <div className="slash-content-item">
                        <div className="slash-content-item-icon">
                            <img src={calenderLogo} alt="logo" />
                        </div>
                        <p>New batch - <b>20th December 2021</b></p>
                    </div>
                    <div className="slash-content-item">
                        <div className="slash-content-item-icon">
                            <img src={calenderOnlyLogo} alt="logo" />
                        </div>
                        <p>Duration - <b>12 weeks</b></p>
                    </div>
                    <button style={{'margin':'25px 0', 'width':'150px', 'height':'40px'}} className="button button-primary" data-url={"/#pricing"} onClick={navToURL}>APPLY NOW</button>
                </div>
            </div>
            <div className="splash-banner">
                <img src={SplashBanner} alt="splash_banner" />
            </div>
        </div>
    );
}

export default Splash;